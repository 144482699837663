import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Virtual } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { ShimmerThumbnail } from "react-shimmer-effects";

const AdvisoryCards = () => {
  const location = useLocation();
  const [activeModal, setActiveModal] = useState(null);
  const modalRef = useRef(null);
  const [swiperRef, setSwiperRef] = useState(null);
  const [showShimmer, setShowShimmer] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowShimmer(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    function handlePopState() {
      if (activeModal !== null) {
        setActiveModal(null);
      }
    }

    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setActiveModal(null);
      }
    }

    window.addEventListener("popstate", handlePopState);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("popstate", handlePopState);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeModal]);

  const cardData = [
    {
      img: require("../../assets/images/AboutUs/Advisor/advisories-images/a-g-nagarkar.webp"),
      name: "Dr. A. G. Nagarkar",
      post: "M.Sc.Ph.D, BME, BAC, HEMC, ATMC",
      data: "Dr. A. G. Nagarkar is a retired Joint Director and senior scientist with the Ministry of Defense, having an illustrious research and scientific product development career spanning 37 years. He completed his MSc and PhD from the University of Pune and underwent higher studies in armament technology and management from DIAT, Khadakwasala. He has studied in Pune and at the Administrative Staff College of India in Hyderabad. He is skilled in advanced research, product development, scientific publishing, and acquiring patents. He has represented India at national expositions, published numerous research papers, and holds several patents to his name. He has been awarded 67 awards, the most notable being the Indian Science Congress and HEMSI EMS. He has conducted lectures and provided expert training at top institutes across India.",
      desc: "Dr. A. G. Nagarkar is a retired Joint Director and senior scientist with the Ministry of Defense,...",
      patents: [
        <>
          <span className="patent-title">Several Patents:</span> Several patents
          were obtained on armour technology and scientific product and process
          development.
        </>,
      ],
      awards: [
        <>
          <span className="award-title">Indian Science Congress:</span> First
          Prize, Thiruvananthapuram.
        </>,
        <>
          <span className="award-title">National Science Day:</span> First prize
          twice for the best presentations.
        </>,
      ],
    },
    {
      img: require("../../assets/images/AboutUs/Advisor/advisories-images/kishore-balasubramanian.webp"),
      name: "Dr. Kishore Balasubramanian",
      post: "NAAC & NIRF member | Expert Career Counselor",
      desc: "Kishore Balasubramanian is an avid academician in information and communication engineering...",
      data: "Kishore Balasubramanian is an avid academician in information and communication engineering with over 20 years of experience. He holds a Ph.D. degree from Anna University, where his topic of research was Glaucoma detection using image processing and machine learning. He is currently working as Associate Professor and Head, Training & Placements, Dr. Mahalingam College of Engineering & Technology, and has organized many seminars and workshops. Kishore has a strong publication record with many publications and has been awarded several grants for academic projects. He has experience in career counselling, curriculum development, ISO auditing, project coordination, technical advisory, effective communication, and organizational abilities. He is actively involved in professional organizations and serves as a reviewer and editorial board member for various esteemed journals.",
      patents: [
        <>
          <span className="patent-title">
            Self-Charging Bicycle with Solar Panel:
          </span>{" "}
          A patent designed for integrating solar panel technology with bicycles
          that provide them with self-charging capabilities was granted on
          August 3, 2022, with Patent Number 402964.
        </>,
        <>
          <span className="patent-title">
            Cost-Effective Convergence Mechanism for Self-Regulating Molding
            Machines in IoT:
          </span>{" "}
          The present patent is aimed at IoT integration in enhancing the
          efficiency of moulding machines via cost-effective convergence
          mechanisms. Publication Date: June 11, 2021.
        </>,
      ],
      awards: [
        <>
          <span className="award-title">Best Teacher Award:</span> He was
          awarded the Best Teacher Award in acknowledgment of his better
          teaching and mentorship skills, which he had towards education and
          student development.
        </>,
        <>
          <span className="award-title">Innovation Excellence Award:</span> He
          was awarded the Innovation Excellence Award for inventing solutions
          that integrate IoT and cloud computing technologies, showing his
          impact on influencing new inventions related to technology.
        </>,
      ],
    },

    {
      img: require("../../assets/images/AboutUs/Advisor/advisories-images/prakash-khodke.webp"),
      name: "Dr. Prakash Khodke ",
      post: "Former Director, MSBTE | Rtd. Principal Govt. College of Engineering, Karad",
      data: "Khodke Prakash Manoharrao, born on October 17, 1960, has recently retired as principal of the Government College of Engineering, where he had an illustrious career both in academics and administration. He did his Ph.D. in mechanical engineering in process modelling of abrasive jet machining. He also has an MBA to his credit. His professional journey spans over four decades, with some pivotal assignments such as Central Project Advisor to the National Project Implementation Unit, MoE, Govt. of India, and Director to the M.S. Board of Technical Education, Mumbai. Dr. Khodke has made an immense contribution to research, as evidenced by his considerable number of publications and patents in the wide domain areas of mechanical engineering, process modelling, supply chain management, and quality management. He has effectively contributed value to the education sector through his skills in educational leadership, administration, curriculum development, research and development, and abrasive jet machining at both the academic and industrial levels.",
      desc: "Khodke Prakash Manoharrao, has recently retired as principal of the Government College of Engineering,...",
      patents: [
        <>
          <span className="patent-title">
            Agriculture waste to paper production:
          </span>{" "}
          Developed with the help of manual equipment.
        </>,
        <>
          <span className="patent-title">Measurement of Velocity:</span>{" "}
          Innovated for open channels.
        </>,
      ],
      awards: [
        <>
          <span className="award-title">
            First Class Various academic qualifications showing academic
            excellence.
          </span>
        </>,
        <>
          <span className="award-title">
            Excellent (A+) reports Confidential reports from the Principal
            Secretary, Government of Maharashtra on Education Contributions.
          </span>
        </>,
      ],
    },
    {
      img: require("../../assets/images/AboutUs/Advisor/advisories-images/shirish-ravan.webp"),
      name: "Dr. Shirish Ravan",
      post: (
        <>
          Former Head at UN SPIDER Beijing Office | ISRO  veteran
        </>
      ),
      data: "Dr. Shirish Ravan has varied professional experience in disaster management, biodiversity, food security, sustainable development, and planetary defence through the application of space and geospatial technologies. With 18 years of varied experience in leadership positions at the United Nations, he significantly contributed to UN-SPIDER and UN-COPUOS, undertaking high-level policy advocacy and technical advisory services across more than 70 countries worldwide. He brings along experience in harnessing space-based technologies, policy advocacy, technical advisory, international conferences, and capacity-building programs. Prior to joining the UN, he spent 12 years working with ISRO, C-DAC, and other institutions in India. Presently heading the Earth Sight Foundation, he is working towards sustainable development through grassroots intervention. He holds a bachelor's in agricultural science, a master's in environmental science, and a Ph.D. in satellite remote sensing, with more than 50 publications to his credit.",
      desc: "Dr. Shirish Ravan has varied professional experience in disaster management, biodiversity, food security,...",
      patents: [
        <>
          <span className="patent-title">
            Innovative Applications of Satellite Remote Sensing:
          </span>{" "}
          Developed novel methodologies that link the concepts of satellite
          remote sensing to forest ecology and biomass estimation.
        </>,
      ],
      awards: [
        <>
          <span className="award-title">Leadership Recognition:</span>{" "}
          Recognized for various leadership roles with the United Nations,
          including coordination of the UN Platform for Space-based Information
          for Disaster Management and Emergency Response.
        </>,
        <>
          <span className="award-title">International Impact:</span> Made
          significant contributions to international disaster management and
          climate resilience through various international conferences and
          capacity-building exercises.
        </>,
      ],
    },
    {
      img: require("../../assets/images/AboutUs/Advisor/advisories-images/manoj-mujumdar.webp"),
      name: "Lt. Manoj Mujumdar",
      post: (
        <>
          Cyber Threat Investigator - Govt. of India | <br /> R&D Intern:
          Cybersecurity - NASA
        </>
      ),
      data: "Mr. Mujumdar is a dynamic Ethical Hacker with a strong background in cybersecurity, dedicated to protecting information systems from potential threats. His expertise lies in identifying and addressing vulnerabilities across various systems, ensuring that robust security measures are in place.Specializing in penetration testing, web application assessments, and network security evaluations,he brings advanced technical skills and a deep understanding of cybersecurity principles. His approach involves conducting thorough assessments to uncover weaknesses and implementing proactive measures to safeguard organizational assets.Mr. Mujumdar is committed to continuous learning and maintaining high ethical standards. By collaborating effectively with cross-functional teams, he contributes to enhancing the overall security posture of the organization, ensuring that all potential risks are addressed and managed effectively.",
      desc: "Mr. Mujumdar is a dynamic Ethical Hacker with a strong background in cybersecurity, dedicated to protecting...",
      patents: [
        <>
          <span className="patent-title">"Extraction of Bugs"</span> sold over
          2,000 copies in just two months.
        </>,
      ],
      awards: [
        <>
          <span className="award-title">Best Cadet Award in India </span>{" "}
        </>,
        <>
          <span className="award-title">
            Received 50+ certificates as the Best Athlete in Maharashtra
          </span>
        </>,
        <>
          <span className="award-title">
            Won the State Level Chess Championship twice
          </span>
        </>,
      ],
    },
    {
      img: require("../../assets/images/AboutUs/Advisor/advisories-images/yogendra-puranik.webp"),
      name: "Mr. Yogendra Puranik",
      post: (
        <>
          Ex-Vice Director, Rakuten Bank | Edogawa City Council | {" "}
          Principal, Tsuchiura First High School
        </>
      ),
      data: "Mr. Yogendra Puranik, commonly referred to as Yogi, has made history in Japan by becoming the first-ever Indian/Asian-origin Assemblyman elected through general elections. He is also Japan's first Indian-origin Gazetted Civil Services Officer (GM rank) and the first foreign-origin Public School Principal. Known for breaking barriers for immigrants, Yogi is often called a 'Walking Institute' due to his extensive knowledge and accomplishments. A prominent figure in Japanese media, Yogi's social work has been widely covered since 2008, including solo interviews and documentaries. His biography, 'Tryst of Destiny with Japan,' was released in Japanese in December 2024.With over 27 years of experience, Yogi is a multi-lingual, techno-functional business professional with a deep understanding of Japanese society and markets, having worked in Japan for over 20 years. His expertise spans various industries such as IT, retail, banking, finance, insurance,automobile, manufacturing, and supply chain. Yogi's strong analytical skills, effective interpersonal abilities, and multilingual proficiency are key to his success.",
      desc: "Mr. Yogendra Puranik, commonly referred to as Yogi, has made history in Japan by becoming the first-ever...",

      awards: [
        <>
          <span className="award-title">
            {" "}
            First Asian-origin Assemblyman (ex) Japan{" "}
          </span>{" "}
        </>,
        <>
          <span className="award-title">
            First Indian-origin Administrative Service Officer in Japan
          </span>
        </>,
        <>
          <span className="award-title">
            First Foreign-origin Public School Principal in Japan
          </span>
        </>,
        <>
          <span className="award-title">
            Head of Edu-DX team at the Government
          </span>
        </>,
      ],
    },
  ];

  return (
    <>
      <div className="advisory-card common-section">
        <div className="container">
          {location.pathname === "/" || location.pathname === "/about-us" ? (
            <>
              <p className="horizontal-styled-span"></p>
              <p className="common-title">
                Advisory <span className="common-title-span">Board</span>
              </p>
            </>
          ) : null}
          <div className="swiper-wrapper-container">
            <Swiper
              modules={[Virtual, Navigation, Pagination]}
              onSwiper={setSwiperRef}
              slidesPerView={3} // Default view for large screens
              spaceBetween={30} // Default space between slides
              pagination={{
                type: "fraction",
              }}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              virtual
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                480: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },

                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1200: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
            >
              {cardData.map((data, ind) => {
                if (
                  location.pathname === "/" ||
                  location.pathname === "/about-us"
                ) {
                  return (
                    <SwiperSlide key={ind} virtualIndex={ind}>
                      <div className="cols my-lg-0 my-3">
                        <div className="text-center">
                          {showShimmer ? (
                            <ShimmerThumbnail
                              className="shimmer-container"
                              width="300"
                              height="400"
                            />
                          ) : (
                            <img
                              src={data.img}
                              alt={`${data.name}_img`}
                              className="advisory-img"
                            />
                          )}

                          <div className="overlay">
                            <div className="d-flex my-auto gap-0">
                              <div className="text-start">
                                <p className="advisory-name">{data.name}</p>
                                <p className="advisory-post">{data.post}</p>
                              </div>
                              <div className="mt-lg-5 mt-3 ms-4 arrow-wrapper">
                                <img
                                  src={
                                    require("../../assets/images/AboutUs/Advisor/arrow.svg")
                                      .default
                                  }
                                  alt="arrow"
                                  className="arrow-img ms-lg-5"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setActiveModal(ind)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="overlay-hover-data d-flex justify-content-center align-items-center flex-column text-center">
                          <p className="advisory-name mb-2">{data.name}</p>
                          <p className="advisory-post">{data.post}</p>
                          <p className="advisory-desc text-center">
                            {data.desc}
                          </p>
                          <div className="text-center mt-3">
                            <button
                              className="overlay-view-more-btn btn"
                              onClick={() => {
                                setActiveModal(ind);
                              }}
                            >
                              View Details
                            </button>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                } else {
                  return null;
                }
              })}
            </Swiper>

            {/* Custom Navigation Arrows */}
            <div className="swiper-navigation">
              <div className="swiper-button-prev"></div>
              <div className="swiper-button-next"></div>
            </div>
          </div>
          {cardData.map((data, ind) => (
            <React.Fragment key={ind}>
              {location.pathname !== "/" &&
                location.pathname !== "/about-us" && (
                  <div>
                    <div className="card advisory-board-card">
                      <div
                        className="d-flex gap-4"
                        style={{
                          borderBottom: "1px solid rgba(219, 235, 254, 1)",
                          marginBottom: "16px",
                        }}
                      >
                        <span>
                          <img
                            src={data.img}
                            alt={`${data.name}_img`}
                            style={{
                              borderRadius: "60px",
                              width: "60px",
                              aspectRatio: 1,
                              border: "1px solid rgba(47, 111, 237, 1)",
                              marginBottom: "10px",
                            }}
                          />
                        </span>
                        <span className="my-auto">
                          <p className="modal-advisory-name">{data.name}</p>
                          <p className="modal-advisory-post">{data.post}</p>
                        </span>
                      </div>
                      <p className="sliced-data mb-0">
                        {data.data.slice(0, Math.ceil(data.data.length / 3))}...
                      </p>
                      <div className="">
                        <button
                          className="view-more-sliceddata-btn btn"
                          onClick={() => setActiveModal(ind)}
                        >
                          View More
                        </button>
                      </div>
                    </div>
                  </div>
                )}

              {/* Modal */}
              {activeModal === ind && (
                <div
                  className={`modal fade show advisory-board-modal`}
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                  style={{ display: "block" }}
                >
                  <div
                    className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
                    ref={modalRef}
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <div className="d-flex gap-4">
                          <span>
                            <img
                              src={data.img}
                              alt={`${data.name}_img`}
                              style={{
                                borderRadius: "60px",
                                width: "80px",
                                aspectRatio: 1,
                                border: "1px solid rgba(47, 111, 237, 1)",
                              }}
                            />
                          </span>
                          <span>
                            <p className="modal-advisory-name mt-2">
                              {data.name}
                            </p>
                            <p className="modal-advisory-post">{data.post}</p>
                          </span>
                        </div>
                        <img
                          src={
                            require("../../assets/images/AboutUs/Advisor/close-icon.svg")
                              .default
                          }
                          alt="close"
                          className="close-img"
                          onClick={() => setActiveModal(null)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div className="modal-body mx-3">
                        <p className="modal-desc">{data.data}</p>
                        <div className="patents">
                          {data?.patents && (
                            <p className="patents-main-title">
                              Patents / Publications
                            </p>
                          )}

                          <ul>
                            {data?.patents?.map((patent, index) => (
                              <li className="patent-award-data" key={index}>
                                {patent}
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="awards">
                          <p className="awards-main-title">
                            Awards & Recognitions
                          </p>
                          <ul>
                            {data?.awards?.map((award, index) => (
                              <li className="patent-award-data" key={index}>
                                {award}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default AdvisoryCards;
